<script setup lang="ts">
import { ref } from 'vue'
import Button from 'primevue/button'
import { useMainStore } from '@/stores/main'

let showMenu = ref(false)
const toggleNav = () => (showMenu.value = !showMenu.value)
const mainStore = useMainStore()
</script>

<template>
  <div id="top-nav" class="bg-gray-900 border-b-1 border-surface-900">
    <nav class="container px-1 py-3 mx-auto">
      <a href="https://leveluplive.com" class="flex items-center font-medium text-sm">
        What is LevelUpLive? Learn more
        <span class="pi pi-arrow-circle-right ml-1"></span>
      </a>
    </nav>
  </div>

  <div id="main-nav" class="bg-surface-700 border-y border-surface-500 mb-8">
    <nav class="container px-1 py-4 mx-auto md:flex md:justify-between md:items-center">
      <div class="flex items-center justify-between">
        <router-link
          to="/"
          class="text-xl font-bold text-gray-100 md:text-2xl hover:text-indigo-400"
        >
          <img src="/image/logo-512x512.png" width="50px" />
        </router-link>

        <!-- Mobile menu button -->
        <div @click="toggleNav" class="flex md:hidden">
          <button
            type="button"
            class="text-gray-100 hover:text-gray-400 focus:outline-none focus:text-gray-400"
          >
            <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <ul
        :class="showMenu ? 'flex' : 'hidden'"
        class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0"
      >
        <li>
          <RouterLink :to="{ name: 'leaderboard' }">
            <Button link label="Leaderboards" icon="pi pi-globe" size="large" />
          </RouterLink>
        </li>
        <li>
          <Button
            label="Player Search"
            link
            size="large"
            icon="pi pi-user"
            @click="mainStore.userSearchModalVisible = true"
          />
        </li>

        <li>
          <RouterLink :to="{ name: 'signup' }">
            <Button size="large" outlined label="Sign Up" icon="pi pi-user-plus" />
          </RouterLink>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style>
ul li {
  @apply text-gray-100 hover:text-indigo-400 font-semibold text-xl;
}
#top-nav a {
  @apply text-gray-300;
}
</style>
