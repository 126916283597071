import { createRouter, createWebHistory } from 'vue-router'
import SignUpView from '@/views/SignUpView.vue'
import PhotoView from '@/views/PhotoView.vue'
import { useMainStore } from '@/stores/main'
import ThankYou from '@/views/ThankYou.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/signup',
      name: 'signup',
      component: () => import('../views/SignUpView.vue')
    },
    {
      path: '/signup/photo',
      name: 'photo',
      component: () => import('../views/PhotoView.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/signup/thanks',
      name: 'thanks',
      // route level code-splitting
      component: () => import('../views/ThankYou.vue')
    },

    {
      path: '/',
      redirect: { name: 'leaderboard' }
    },

    {
      path: '/leaderboard',
      name: 'leaderboard',
      // route level code-splitting
      component: () => import('../views/Leaderboard/IndexView.vue')
    },
    {
      path: '/leaderboard/tv',
      name: 'leaderboard_tv',
      // route level code-splitting
      component: () => import('../views/Leaderboard/TvView.vue')
    },

    {
      path: '/leaderboard/users/:gamerTag',
      name: 'userLeaderboard',
      // route level code-splitting
      component: () => import('../views/Leaderboard/UserView.vue')
    }
  ]
})

router.beforeEach((to) => {
  const mainStore = useMainStore()

  if (to.meta.requiresAuth && !mainStore.loggedIn) {
    return '/'
  }
})

export default router
