import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import axios from 'axios'
import { createPinia } from 'pinia'

import 'primeicons/primeicons.css'
import './assets/main.css'
import Lara from '@primevue/themes/lara'
import { useMainStore } from '@/stores/main'
import type { FacilityDTO, GameDTO, GameModeDTO, GameTypeDTO } from '@/lib/interfaces'
import { Facility } from '@/classes/Facility'
import { Game } from '@/classes/Game'
import { GameMode } from '@/classes/GameMode'
import { GameType } from '@/classes/GameType'
import { definePreset } from '@primevue/themes'
import * as Sentry from '@sentry/vue'

import dayjs from 'dayjs'

// DayJS
import duration from 'dayjs/plugin/duration'

// Axios Defaults
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true

const app = createApp(App)

const MyPreset = definePreset(Lara, {
  semantic: {
    primary: {
      50: '{green.50}',
      100: '{green.100}',
      200: '{green.200}',
      300: '{green.300}',
      400: '{green.400}',
      500: '{green.500}',
      600: '{green.600}',
      700: '{green.700}',
      800: '{green.800}',
      900: '{green.900}',
      950: '{green.950}'
    }
  }
})

app.use(createPinia())
app.use(router)
app.use(PrimeVue, {
  theme: {
    preset: MyPreset,
    options: {
      prefix: 'p',
      darkModeSelector: '.dark-app',
      cssLayer: false
    }
  }
})

dayjs.extend(duration)

// Fetch System Information
const mainStore = useMainStore()

axios.get(`${mainStore.apiUrl}/system`).then(function (response) {
  const facilityDTOs = response.data['data']['facilities']
  facilityDTOs.forEach((facilityDTO: FacilityDTO) => {
    const facility: Facility = new Facility(facilityDTO.id, facilityDTO.name)
    mainStore.facilities.push(facility)
  })

  const gameDTOs = response.data['data']['games']
  gameDTOs.forEach((gameDTO: GameDTO) => {
    const game: Game = new Game(gameDTO.id, gameDTO.name, gameDTO.game_type_id)
    mainStore.games.push(game)
  })

  const gameModeDTOs = response.data['data']['game_modes']
  gameModeDTOs.forEach((gameModeDTO: GameModeDTO) => {
    const gameMode: GameMode = new GameMode(gameModeDTO.id, gameModeDTO.name)
    mainStore.gameModes.push(gameMode)
  })

  const gameTypeDTOs = response.data['data']['game_types']
  gameTypeDTOs.forEach((gameTypeDTO: GameTypeDTO) => {
    const gameType: GameType = new GameType(gameTypeDTO.id, gameTypeDTO.name)
    mainStore.gameTypes.push(gameType)
  })
})

Sentry.init({
  app,
  dsn: 'https://9c4bcdb745fba68f71aa7ac964d1f9b2@o4508145131388928.ingest.us.sentry.io/4508145303486464',
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api\.leveluplive\.com\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

app.mount('#app')
