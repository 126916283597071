<script setup lang="ts">
import type { User } from '@/classes/User'

const props = withDefaults(
  defineProps<{
    user: User
    height?: string
    width?: string
  }>(),
  {
    height: 'h-12',
    width: 'w-12'
  }
)
</script>

<template>
  <div class="avatar">
    <img
      :src="props.user.imageUrl"
      alt="Player Image"
      class="rounded-full object-cover"
      :class="[props.width, props.height]"
    />
  </div>
</template>

<style></style>
