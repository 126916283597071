import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import type { Facility } from '@/classes/Facility'
import type { Game } from '@/classes/Game'
import type { GameMode } from '@/classes/GameMode'
import type { User } from '@/classes/User'
import type { GameType } from '@/classes/GameType'
import axios from 'axios'

export const useMainStore = defineStore('main', {
  state: () => {
    return {
      facilities: [] as Facility[],
      games: [] as Game[],
      gameModes: [] as GameMode[],
      gameTypes: [] as GameType[],
      loggedIn: useStorage('loggedIn', false),
      userSearchModalVisible: false,
      users: new Map<string, User>()
    }
  },

  getters: {
    baseApiUrl(): string {
      return import.meta.env.VITE_API_URL
    },

    apiUrl(): string {
      return this.baseApiUrl + '/api/web/v1'
    }
  },

  actions: {
    async logoutUser() {
      this.loggedIn = false

      try {
        // Log the user out so that we can do a new sign up
        await axios.post(`${this.baseApiUrl}/logout`)
      } catch (error) {
        console.log('User not logged in and could not logout.')
      }
    }
  }
})
