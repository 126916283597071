export class Game {
  readonly id: number
  readonly name: string
  readonly gameTypeId: number

  constructor(id: number, name: string, gameTypeId: number) {
    this.id = id
    this.name = name
    this.gameTypeId = gameTypeId
  }

  hitBased() {
    return this.gameTypeId === 1
  }

  timeBased() {
    return this.gameTypeId === 2
  }
}
