<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import { useMainStore } from '@/stores/main'
import { ref } from 'vue'
import axios from 'axios'
import type { UserIndexDTO } from '@/lib/interfaces'
import { User } from '@/classes/User'
import UserAvatar from '@/components/UserAvatar.vue'

const mainStore = useMainStore()
const searchQuery = ref('')
const userResults = ref<User[]>([])
const noResultsFound = ref(false)

const searchUpdate = useDebounceFn(() => {
  userResults.value = []

  if (searchQuery.value.length > 2) {
    noResultsFound.value = false

    fetchResults()
  }
}, 250)

const fetchResults = async () => {
  axios
    .get(`${mainStore.apiUrl}/leaderboard/users`, {
      params: {
        search_query: searchQuery.value
      }
    })
    .then(function (response) {
      userResults.value = []

      const userDTOs: UserIndexDTO[] = response.data['data']
      userDTOs.forEach((userDTO: UserIndexDTO) => {
        // Look up User object
        let user = mainStore.users.get(userDTO.gamer_tag)
        if (!user) {
          user = new User(userDTO.gamer_tag, userDTO.image_url)
          mainStore.users.set(userDTO.gamer_tag, user)
        }

        userResults.value.push(user)
      })

      if (userResults.value.length === 0) {
        noResultsFound.value = true
      }
    })
}
</script>

<template>
  <Dialog
    v-model:visible="mainStore.userSearchModalVisible"
    modal
    header="Player Search"
    :style="{ width: '40rem', 'max-height': '550px' }"
    :dismissable-mask="true"
    :draggable="false"
  >
    <IconField class="mb-2">
      <InputIcon class="pi pi-search" />
      <InputText
        v-model="searchQuery"
        placeholder="Search by Gamer Tag"
        autofocus
        @input="searchUpdate"
      />
    </IconField>
    <small class="text-gray-300">Please enter at least 3 characters.</small>

    <div class="mt-4">
      <div v-if="noResultsFound">No Results Found</div>
      <div v-for="user in userResults" :key="user.gamerTag">
        <div class="grid grid-cols-2 gap-4 mt-6">
          <div class="flex items-center">
            <UserAvatar :user="user" />
            <span class="ml-4">{{ user.gamerTag }}</span>
          </div>

          <div class="flex items-center">
            <RouterLink
              :to="{ name: 'userLeaderboard', params: { gamerTag: user.gamerTag } }"
              @click="mainStore.userSearchModalVisible = false"
            >
              <Button label="View Profile" outlined size="small" icon="pi pi-user" />
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>
